.connectForm {
  box-sizing: border-box;
  width: 99%;
  height: 100%;
  display: flex;
  margin-top: 100px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &_success {
    text-align: center;
    color: #00883d;
    font-weight: 500;
    margin: 15px 0;
    margin-top: 0;
    height: 20px;
  }

  &_error {
    margin-top: 4px;
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: bold;
    color: #fa3951;
    text-align: center;
  }

  &_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    & button {
      background-image: url("../../assets/images/buttonBg.png");
      background-position: 4% 92%;
      background-size: 250%;

      color: white;
      border: none;
      padding: 10px 25px;
      cursor: pointer;
      font-size: 17px;
      font-weight: 600;
      border-radius: 120px;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
      &:active,
      &:focus {
        outline: none;
      }
    }
  }

  &_selectInput {
    width: 100%;
    padding: 5px 5px;
    margin-bottom: 20px;
    color: #172b4d;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    font-size: 15px;

    &:focus {
      border: 1px solid #b8b8b8;
      outline: none;
    }

    &:active {
      border: 1px solid #b8b8b8;
    }
  }

  &_loadingState {
    margin: 20px 0;
  }

  &_box {
    background-color: white;
    padding: 30px;
    border-radius: 7px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;

    @media screen and (max-width: 700px) {
      padding: 20px 10px;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1eb0fc;
    border-radius: 10px;
  }

  &_title {
    color: #757575;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #bcbcbc;
    padding-bottom: 10px;
    margin-bottom: 15px;

    &__left {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__icon {
      width: 40px;
      margin-right: 5px;
      transform: translateY(-2px);
    }

    & h2 {
      margin: 0;
      font-weight: 500;
    }
  }

  &_goBack {
    cursor: pointer;
    padding: 7px 14px;
    border: 1px solid #614b4b21;
    border-radius: 5px;
    box-sizing: border-box;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      width: 20px;
    }
  }

  &_revoke,
  &_connectBtn {
    cursor: pointer;
    padding: 10px 20px;
    font-size: 15px;
    border-radius: 7px;
    color: white;
    font-family: inherit;
    box-sizing: border-box;
  }

  &_revoke {
    border: 1px solid #fa3951;
    background-color: #fa3951;

    &:hover {
      background-color: #ff6879;
    }
  }

  &_connectBtn {
    border: 1px solid #1eb0fc;
    background-color: #1eb0fc;

    &:hover {
      background-color: #5dc0f5;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &_shopifySubmit {
    cursor: pointer;
    padding: 7px 15px;
    font-size: 15px;
    border: 1px solid #1eb0fc;
    border-radius: 5px;
    background-color: #1eb0fc;
    color: white;
    box-sizing: border-box;
    &:hover {
      background-color: #5dc0f5;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }

  &_buttons {
    display: flex;
    align-items: stretch;
  }

  &_form {
    width: 100%;
    margin: 5px auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__inputContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      width: 100%;
      & label {
        margin-bottom: 5px;
        color: #434343;
        font-size: 15px;
      }

      & input,
      &__input {
        width: 100%;
        font-size: 15px;
        box-sizing: border-box;
        padding: 10px;
        border: 1px solid #e5e5e5;
        color: #434343;
        border-radius: 5px;
        &:focus {
          border: 1px solid #adadad;
          outline: none;
        }
      }

      & input::-webkit-outer-spin-button,
      & input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      
      & input[type="number"] {
        -moz-appearance: textfield;
      }
    }

    &__errorMessage {
      margin-top: 4px;
      font-size: 14px;
      margin-bottom: 15px;
      font-weight: bold;
      color: #fa3951;
      text-align: center;
    }
    &__successMessage {
      margin-top: 4px;
      font-size: 14px;
      margin-bottom: 15px;
      font-weight: bold;
      color: #01e467;
    }
  }
}

.propertiesList {
  max-height: 200px;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  & button {
    margin-bottom: 10px;
  }

  &_item {
    cursor: pointer;
    padding: 7px 20px;
    font-size: 15px;
    border: 1px solid #1eb0fc;
    border-radius: 5px;
    background-color: #1eb0fc;
    color: white;
    box-sizing: border-box;
    margin-bottom: 10px;
    width: 99%;
    display: inline-block;

    &:hover {
      background-color: #5dc0f5;
    }

    & span:first-child {
      float: left;
    }
    & span:last-child {
      float: right;
    }
  }
}

.instructions {
  margin-top: 20px;
  background-color: white;
  padding: 30px;
  border-radius: 7px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  max-width: 750px;

  & a {
    text-decoration: none;
  }

  & h2 {
    margin-bottom: 5px;
  }

  &__closeButton {
    cursor: pointer;
    background-color: #fa3951;
    color: #fff;

    padding: 7px 20px;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    margin-right: 10px;
    &:hover {
      background-color: #f82d45;
    }
    &:active,
    &:focus {
      outline: none;
    }
  }

  &__cancelButton {
    cursor: pointer;
    background-color: #302e2f;
    color: #fff;

    padding: 7px 20px;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    margin-right: 10px;
    &:hover {
      background-color: #1a1819;
    }
    &:active,
    &:focus {
      outline: none;
    }
  }

  &_block {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    & p {
      font-size: 17px;
    }

    & img {
      width: 100%;
    }
  }

  &_buttons {
    display: inline-block;

    &__left {
      float: left;
    }
    &__right {
      float: right;
    }
  }
}

.inputsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  width: 100%;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    row-gap: 10px;
  }

  &_brand {
    width: 100%;
    @media screen and (max-width: 600px) {
      text-align: center;
    }
  }
}
